import axios from 'axios'
import { useAuthStore } from '@/stores/auth'
import router from '@/router'

let sourceRequest = {}
let currentRoute = null

// Whitelist of endpoints that should not be cancelled on route change
const WHITELIST_ENDPOINTS = ['/csrf-cookie', '/me', 'risks/options', '/options']

const isWhitelisted = (url) => {
	return WHITELIST_ENDPOINTS.some((endpoint) => url.includes(endpoint))
}

// Update current route when route changes
router.afterEach((to) => {
	const previousRoute = currentRoute
	currentRoute = to.fullPath

	// Cancel requests from previous route, except whitelisted ones
	if (previousRoute && previousRoute !== currentRoute) {
		Object.keys(sourceRequest).forEach((key) => {
			if (sourceRequest[key] && sourceRequest[key].route === previousRoute && !isWhitelisted(sourceRequest[key].url)) {
				sourceRequest[key].cancel('Route Changed')
				delete sourceRequest[key]
			}
		})
	}
})

const client = axios.create({
	baseURL: process.env.VUE_APP_API_BASE_URL,
	withCredentials: true,
	headers: {
		'X-Requested-With': 'XMLHttpRequest',
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'X-Frame-Options': 'DENY',
		'X-Content-Type-Options': 'nosniff',
		'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
	},
	xsrfCookieName: 'XSRF-TOKEN',
	xsrfHeaderName: 'X-XSRF-TOKEN',
	// Add timeout to prevent hanging requests
	timeout: 30000,
})

client.CancelToken = axios.CancelToken

// Add cleanup function to cancel all pending requests
const cancelAllPendingRequests = () => {
	Object.keys(sourceRequest).forEach((key) => {
		if (sourceRequest[key]) {
			sourceRequest[key].cancel('Route Changed')
			delete sourceRequest[key]
		}
	})
}

// Add request retry mechanism
const MAX_RETRIES = 0
const isRetryableError = (error) => {
	return axios.isAxiosError(error) && (!error.response || [408, 500, 502, 503, 504].includes(error.response.status))
}

client.interceptors.request.use(
	(request) => {
		const requestKey = `${request.url}-${Date.now()}`

		// Only cancel previous request if it's from a different route and not whitelisted
		if (sourceRequest[requestKey] && sourceRequest[requestKey].route !== currentRoute && !isWhitelisted(request.url)) {
			sourceRequest[requestKey].cancel('Route Changed')
			delete sourceRequest[requestKey]
		}

		const axiosSource = axios.CancelToken.source()
		sourceRequest[requestKey] = {
			cancel: axiosSource.cancel,
			route: currentRoute,
			url: request.url,
		}
		request.cancelToken = axiosSource.token

		// Only add socket ID if Echo is initialized
		if (window.Echo && window.Echo.socketId) {
			request.headers['X-Socket-ID'] = window.Echo.socketId()
		}

		// Add request timestamp
		request.metadata = { startTime: new Date().getTime() }

		return request
	},
	(error) => {
		return Promise.reject(error)
	}
)

client.interceptors.response.use(
	(response) => {
		// Clean up the source request after successful response
		const requestKey = `${response.config.url}-${response.config.metadata.startTime}`
		if (sourceRequest[requestKey]) {
			delete sourceRequest[requestKey]
		}

		// Add response time monitoring
		const endTime = new Date().getTime()
		const duration = endTime - response.config.metadata.startTime
		response.duration = duration

		return response
	},
	async (error) => {
		// Clean up the source request after error
		if (error.config) {
			const requestKey = `${error.config.url}-${error.config.metadata.startTime}`
			if (sourceRequest[requestKey]) {
				delete sourceRequest[requestKey]
			}
		}

		const originalRequest = error.config

		// Implement retry logic for certain errors
		if (isRetryableError(error) && (!originalRequest._retry || originalRequest._retry < MAX_RETRIES)) {
			originalRequest._retry = (originalRequest._retry || 0) + 1
			const backoffDelay = Math.min(1000 * 2 ** (originalRequest._retry - 1), 10000)

			await new Promise((resolve) => setTimeout(resolve, backoffDelay))
			return client(originalRequest)
		}

		// Handle authentication errors
		if (error.response && [401, 403, 419].includes(error.response.status)) {
			const authStore = useAuthStore()
			authStore.resetUser()

			// Redirect to login with return URL
			const currentPath = window.location.pathname
			router
				.push({
					name: 'login',
					query: { redirect: currentPath },
				})
				.catch(() => {})
		}

		return Promise.reject(error)
	}
)

// Export the cancel function to be used by router guards
export const cancelRequests = cancelAllPendingRequests
export default client
