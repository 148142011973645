<template>
	<div id="app">
		<rysqer-layout> <router-view></router-view></rysqer-layout>
	</div>
</template>

<script setup>
import { ref, reactive, computed, getCurrentInstance, provide } from 'vue'

import RysqerLayout from '@/layout/Layout'

import { useAuthStore } from '@/stores/auth'
import { useAppStore } from '@/stores/apps'
import { useStageStore } from '@/stores/stage'
import { useLibsStore } from '@/stores/libs'
import { useFormattersStore } from '@/stores/formatters'
import { useOptionsStore } from '@/stores/options'

import { useTitle, usePreferredLanguages, useEventBus } from '@vueuse/core'

import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import axios from '@/libs/axios'

import { clickToDownload, b64Decode, b64Encode } from '@/libs/functions'

const preferredLanguages = usePreferredLanguages()
const locale = preferredLanguages.value?.[0] || 'en-US' // Default to en-US

const bus = useEventBus('rysqer-events')

const createEventBus = () => {
	return {
		on(event, callback) {
			return bus.on((payload) => {
				if (payload && payload.event === event) {
					callback(payload.data)
				}
			})
		},
		emit(event, data) {
			bus.emit({ event, data })
		},
		off(event, callback) {
			bus.off(callback)
		},
		reset() {
			bus.reset()
		},
	}
}

const vm = getCurrentInstance()
const router = vm.proxy.$router
const route = computed(() => vm.proxy.$route)

const title = useTitle()
title.value = 'Rysqer Tool'

if (useAuthStore().authenticated) {
	useOptionsStore().hydrate()
}

const Rysqer = () => {
	return reactive({
		apps: useAppStore().apps,
		active: useAppStore().active,
		createApp: useAppStore().createApp,
		createView: useAppStore().createView,
		createScene: useAppStore().createScene,
		stages: useStageStore().stages,
		stage: useStageStore(),
		sizes: useStageStore().sizes,
		breakpoints: useStageStore().bp,
		log: console.log,
		libs: {
			vm: vm,
			toast: vm.proxy.$toast,
			dialog: vm.proxy.$dialog,
			router: router,
			route: route,
			uuid: uuidv4,
			log: console.log,
			table: console.table,
			debounce: useLibsStore().debounce,
			moment: moment,
			axios: axios,
			clickToDownload: clickToDownload,
			b64Decode: b64Decode,
			b64Encode: b64Encode,
			getCanvas: useLibsStore().getCanvas,
			downloadAsPdf: useLibsStore().downloadAsPdf,
			downloadAsJpeg: useLibsStore().downloadAsJpeg,
			getDateTimeString: () => {
				return moment().format('YYYY-MM-DD-hhmmss')
			},
			locale: locale,
		},
		temp: {},
		formatters: useFormattersStore(),
		auth: useAuthStore(),
		can: useAuthStore().can,
		quicklink: {
			uuid: null,
			handle() {
				this.uuid = rysqer.libs.route.params.uuid
			},
			reset(routerName) {
				this.uuid = null
				router.replace({ name: routerName }).catch(() => {})
			},
		},
		options: useOptionsStore(),
		broadcasting: createEventBus(),
		events: {
			ticket_changed: 'ticket.changed',
			tickets_requests_refresh: 'tickets.requests.refresh',

			control_created: 'control.created',
			control_changed: 'control.changed',
			control_instance_changed: 'control.instance.changed',
			control_walkthrough_changed: 'control.walkthrough.changed',
			control_assessment_created: 'control.assessment.created',
			control_assessment_changed: 'control.assessment.changed',

			risk_created: 'risk.created',
			risk_changed: 'risk.changed',
			risk_instance_changed: 'risk.instance.changed',

			esg_materialities_created: 'esg.materialities.created',
			esg_materialities_changed: 'esg.materialities.changed',
			esg_materialities_deleted: 'esg.materialities.deleted',
			esg_validation_created: 'esg.validation.created',
			esg_validation_changed: 'esg.validation.changed',
			esg_assessment_created: 'esg.assessment.created',
			esg_assessment_changed: 'esg.assessment.changed',
			esg_data_changed: 'esg.data.changed',

			esg_calculator_created: 'esg.calculator.created',
			esg_calculator_changed: 'esg.calculator.changed',

			user_created: 'user.created',
			user_changed: 'user.changed',

			tasks_start: 'tasks.start',

			activity_clicked: 'activity.clicked',
		},
	})
}

let rysqer = Rysqer()

provide('rysqer', rysqer)

defineExpose({ rysqer })
</script>
