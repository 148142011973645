import { defineStore } from 'pinia'
import moment from 'moment'
import { truncate as _truncate, upperFirst as _upperFirst, lowerFirst as _lowerFirst } from 'lodash-es'

export const useFormattersStore = defineStore('rysqer-formatters-store', () => {
	const capitalize = (value) => {
		if (!value) return ''
		value = value.toString()
		return value.charAt(0).toUpperCase() + value.slice(1)
	}

	const dateTime = (value) => {
		if (value == null) return '-'
		return moment(value).format('MMM DD, YY hh:mm a')
	}

	const date = (value) => {
		if (value == null) return '-'
		return moment(value).format('MMM DD, YY')
	}

	const time = (value) => {
		if (!value) return ''
		return moment(String(value)).format('hh:mm a')
	}

	const dateTimeSent = (value) => {
		if (!value) return ''
		let date = moment(String(value))
		if (date.isSame(moment(), 'day')) return date.format('hh:mm a')
		else return moment(String(value)).format('DD. MMM YY hh:mm a')
	}

	const longDateTime = (value) => {
		if (!value) return ''
		return moment(String(value)).format('ddd, MMM Do YYYY, h:mm:ss a')
	}

	const utcLocalDateTime = (value) => {
		if (value == null) return '-'
		var utc = moment.utc(value).toDate()
		return moment(utc).local().format('MMM DD, YY hh:mm a')
	}

	const quarter = (value) => {
		if (!value) return ''
		return moment(String(value)).quarter()
	}

	const year = (value) => {
		if (!value) return ''
		return moment(String(value)).year()
	}

	const trunc = (value, length = 30) => {
		if (!value) return ''
		return _truncate(value, { length: length })
	}

	const upperFirst = (value) => {
		if (!value) return ''
		return _upperFirst(value)
	}

	const lowerFirst = (value) => {
		if (!value) return ''
		return _lowerFirst(value)
	}

	const prettyJson = (value) => {
		return JSON.stringify(JSON.parse(value), undefined, 2)
	}

	const prettyObject = (value) => {
		return JSON.stringify(value, undefined, 2)
	}

	const prettyBytes = (num) => {
		if (!num) return ''
		if (typeof num !== 'number' || isNaN(num)) return ''

		var exponent
		var unit
		var neg = num < 0
		var units = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

		if (neg) {
			num = -num
		}

		if (num < 1) {
			return (neg ? '-' : '') + num + ' B'
		}

		exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1)
		num = (num / Math.pow(1000, exponent)).toFixed(2) * 1
		unit = units[exponent]

		return (neg ? '-' : '') + num + ' ' + unit
	}

	const todaysTimeOrDate = (value) => {
		if (moment(value).isBefore(moment.now())) {
			return moment(value).format('MMM DD, YY')
		} else {
			return moment(value).format('hh:mm a')
		}
	}

	const htmlToText = (value) => {
		return value.replace(/<[^>]+>/g, '')
	}

	const ynd = (value) => {
		if (value == null) return '-'
		else if (!!value) return 'Yes'
		else return 'No'
	}

	const ynn = (value) => {
		if (value == null) return ''
		else if (!!value) return 'Yes'
		else return 'No'
	}

	return {
		capitalize,
		date,
		time,
		dateTime,
		dateTimeSent,
		longDateTime,
		utcLocalDateTime,
		quarter,
		year,
		trunc,
		upperFirst,
		lowerFirst,
		prettyJson,
		prettyObject,
		prettyBytes,
		todaysTimeOrDate,
		htmlToText,
		ynd,
		ynn,
	}
})
