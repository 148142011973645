import Vue from 'vue'
import VueTailwind from 'vue-tailwind'

import tInput from './src/libs/tailwindVue/tInput'
import tRichSelect from './src/libs/tailwindVue/tRichSelect'
import tDialog from './src/libs/tailwindVue/tDialog'
import tModal from './src/libs/tailwindVue/tModal'

const settings = {
  't-input': tInput,
  't-rich-select': tRichSelect,
  't-dialog': tDialog,
  't-modal': tModal,
}

Vue.use(VueTailwind, settings)
